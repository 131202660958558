svg.active{
    fill: #E31C24;
}

.paper {
    position: absolute;
    width: 100%;
    background-color: transparent;
    box-shadow: 5px 10px;
}

.dark > div:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.9);
}
.slick-slider > div{
    background-color: transparent;
}