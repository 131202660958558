$_button-background-color: $action-color;
$_button-background-color-hover: shade($action-color, 20%);

#{$all-buttons} {
  appearance: none;
  background-color: $_button-background-color;
  border: 0;
  border-radius: $base-border-radius;
  color: contrast-switch($_button-background-color);
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: $small-spacing $base-spacing;
  text-align: center;
  text-decoration: none;
  transition: background-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:focus {
    outline: none;
    outline-offset: inherit;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $_button-background-color;
    }
  }
}
